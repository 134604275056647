@import "./variables.scss";
html, body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: 'Noto Sans', Avenir, Helvetica, Arial, sans-serif;
}

a {
  text-decoration: none;
  color: #333;

  &:hover {
    text-decoration: none;
    color: #333;
  }
}

.btn {
  font-weight: bold;
  font-size: 15px;
  height: 45px;
  background-color: #fff;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  img {
    vertical-align: middle;
  }
}

.btn-round {
  border-radius: 20px;
  border: 2px solid $pink;
  padding: 0 20px;
}

.btn-pink {
  background-color: $pink;
  color: #fff;
}
