
li {
  display: block;
  min-width: 280px;
  max-width: 380px;
  // align-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-items: flex-start;
  // border: 1px solid rgba(0,0,0,0.2);
  margin: 0;
  margin-bottom: 15px;
  padding: 10px;
  // box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
  width: auto;

  .thumbnail-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    img {
      // position: absolute;
      top: 0;

      width: 100%;
      height: 200px;
      object-fit: cover;

      border-radius: 0px;
      background-color: #eee;
      transition: filter 0.3s;

      &:hover {
        filter: brightness(70%);
        transition: filter 0.3s;
      }
    }
  }

  .title * {
    color: #000;
  }

  .author *,
  .info * {
    color: #666;
  }
}
