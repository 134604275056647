
@import "../common.scss";

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-decorator {
  width: 460px;
  height: 25px;
  margin: 0px auto;
  padding: 0;
}

.modal-container {
  width: 400px;
  height: 530px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  // border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: "Noto Sans", Arial, sans-serif;
}

.modal-header {
  width: 100%;
  height: 40px;
  border-bottom: 3px solid $pink;

  h3 {
    float: left;
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 30px;
  }
}

.modal-body {
  margin: 20px 0;

  float: left;
}

.modal-close-button {
  float: right;
  border: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  font-size: 30px;

  &:focus {
    outline: none;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.05);
}

.login-form {

  input[type="text"],
  input[type="password"] {
    display: block;
    background-color: rgb(234, 234, 234);
    border: none;
    border-radius: 10px;
    width: 350px;
    height: 40px;
    padding: 10px 20px;
    margin-bottom: 15px;

    &:focus {
      outline: none;
    }
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .login-button,
  .signup-button {
    width: 390px;
    margin-bottom: 10px;
  }

  .find-pw {
    float: right;
    padding-right: 10px;

    a {
      text-decoration: none;
      color: #666;
      font-weight: bold;
    }
  }
}
