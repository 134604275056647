
.video-view {
  width: 100%;
  height: auto;
  overflow: visible;
  padding: 0;

  background-color: #eee;

}

.container {
  display: flex;
  grid-template-columns: auto 300px;
  grid-template-rows: auto;
  grid-template-areas: "main bottom";
}

.qr-container {
  position: fixed;
  top: 100px;
  right: 10px;
  width: 165px;
  height: 165px;
  z-index: 30;
}
