
.annotation-bullet-bar {
  pointer-events: none;

  .clickable {
    pointer-events: auto;
  }

  .time-range {
    font-size: 0.7rem;
    font-weight: 700;
  }
}
