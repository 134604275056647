
.video-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: auto;

  .video-window {
    position: relative;
    width: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-size: cover;
    overflow: hidden;
    object-fit: fill;

    img {
      object-fit: cover;
    }
  }

  .annotation {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    .overlay-tag {
     /* border: 3px solid blue;*/
      position: absolute;
      pointer-events: all;
      cursor: pointer;
      object-fit: contain;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h5 {
      color: white;
      line-height: 1.5em;
      background-color: blue;
      margin: 0;
      padding: 0;
    }
  }

  .seeker-bar {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    bottom: 0px;
    z-index: 10;
  }

  .popup-float {
    position: absolute;
    margin-left: auto;
    top: 10px;
    right: 10px;
    min-width: 100px;
    max-width: 50%;
    min-height: 30%;
    max-height: 90%;
    overflow: scroll;
    border: none; /* 2px dashed #ccc; */
    background: transparent; /* rgba(255, 255, 255, 0.8); */

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .popup-fixed {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    bottom: 1%;
    overflow: hidden;
    border: none; /* 2px dashed #ccc; */
    background: transparent; /* rgba(255, 255, 255, 0.8); */

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .popup .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 80px;
    height: 30px;
    border: 3px solid red;
    color: red;
    background-color: white;
    z-index: 30;
    font-family: monospace;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 2px;
    text-align: center;
    margin: 0;
    padding: 1px;

    &:hover {
      color: white;
      background-color: red;
    }
  }
}

.info-wrapper {
  display: block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.inline {
  display: flex;
  justify-content: flex-start;
}

.edit-button {
  margin-left: 10px;
}
