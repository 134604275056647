
$card-width: 280px;

.columns {
  display: flex;
  flex-wrap: wrap;
  column-width: $card-width;
  column-gap: 15px;
  justify-content: space-between;
}
