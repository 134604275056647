
#app-main {
  width: 100vw;
  height: 100vh;
  overflow: scroll;

  header {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100px;
    background-color: white;
    z-index: 100;
    overflow: hidden;
  }

  section.body {
    padding-right: 5px;
  }

  .footer {
    z-index: 30;
    border-top: 1px solid rgb(221, 221, 221);
    width: 100%;
    background-color: #fff;
    bottom: 30px;
    //padding-bottom: 30px;
    // ref: https://bootcamp.uxdesign.cc/you-have-to-start-using-this-css-property-in-your-websites-7353f46def79
    padding-bottom: calc(env(safe-area-inset-bottom) + 30px);

    * {
      font: normal normal 600 16px/22px Open Sans;
      font-size: 18px;
      color: #333;
    }

    img.logo {
      vertical-align: middle;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
